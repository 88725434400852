import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import { Row, Col } from 'antd'

import PageLoad from './components/PageLoad'

// Layout
import { SimpleBlogLayout, BasicLayout, LandingLayout } from './layouts'

const NotFound = () => {
  return (
    <div className="container container-sm direction-column my-40 with-gutter prose">
      <Row>
        <Col span={24}>
          <h2 className="bigHeading">
            {"Sorry! That page doesn't seem to exist."}
          </h2>
        </Col>
      </Row>
    </div>
  )
}
export const routesConfig = [
  {
    path: '/training',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Training',
    component: lazy(() => import('./views/landing'))
  },
  {
    path: '/',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Home',
    component: lazy(() => import('./views/home'))
  },
  {
    path: '/sales',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Sales',
    component: lazy(() => import('./views/sales'))
  },
  {
    path: '/scenario-builder',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Scenario Builder',
    component: lazy(() => import('./views/scenario-builder'))
  },
  {
    path: '/data-analytics',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Data Analytics',
    component: lazy(() => import('./views/data-analytics'))
  },
  {
    path: '/ai-assisted-progressions',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'AI Assisted',
    component: lazy(() => import('./views/ai-assisted'))
  },
  {
    path: '/about-us',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'About Us',
    component: lazy(() => import('./views/aboutus'))
  },
  {
    path: '/contact-us',
    layout: LandingLayout,
    exact: true,
    breadcrumbName: 'Contact',
    component: lazy(() => import('./views/contact'))
  },
  {
    path: '/privacy-policy',
    layout: SimpleBlogLayout,
    exact: true,
    breadcrumbName: 'Privacy Policy',
    component: lazy(() => import('./views/innerPages/privacyPolicy'))
  },
  {
    path: '/terms-and-conditions',
    layout: SimpleBlogLayout,
    exact: true,
    breadcrumbName: 'Terms and Conditions',
    component: lazy(() => import('./views/innerPages/termsAndConditions'))
  },
  {
    path: '/training-providers',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Training Providers',
    component: lazy(() => import('./views/trainingProviders'))
  },

  {
    path: '/whitepaper',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Whitepaper',
    component: lazy(() => import('./views/whitepaper'))
  },
  {
    path: '/slack',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Slack',
    component: lazy(() => import('./views/slack'))
  },
  {
    path: '/focus-groups',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Focus Groups',
    component: lazy(() => import('./views/focusGroup'))
  },
  {
    path: '/ergs',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'For ERGs',
    component: lazy(() => import('./views/ergs'))
  },
  {
    path: '/sandboxes',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Sandboxes',
    component: lazy(() => import('./views/sandboxes'))
  },
  {
    path: '/get-started',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Get Started',
    component: lazy(() => import('./views/getstarted'))
  },
  {
    path: '*',
    breadcrumbName: '404 Not Found',
    layout: BasicLayout,
    component: NotFound
  }
]

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<PageLoad />}>
      <Switch>
        {routes.map((route, i) => {
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component
                      title={
                        route.breadcrumbName
                          ? `${route.breadcrumbName} : Tapestree`
                          : 'Tapestree'
                      }
                      {...props}
                    />
                  )}
                </Layout>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes

import React from 'react'
import '@/styles/main.less'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { configureStore } from './services'
import App from './App'
import GA4React from 'ga-4-react'

const store = configureStore()
const ga4react = new GA4React(process.env.TRACKING_ID)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)

try {
  ga4react.initialize()
} catch (err) {
  console.log('Google Analytics failed', err)
}

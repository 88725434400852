import React, { memo } from 'react'
import { Row, Col, Divider, Space } from 'antd'

import { Link } from 'react-router-dom'
import styles from './layout.module.less'

import LogoIcon from '@/assets/images/logo.png'
import TwitterIcon from '../styles/icons/twitterIcon'
import DribbleIcon from '../styles/icons/dribbleIcon'
import YoutubeIcon from '../styles/icons/youtubeIcon'

const currentYear = new Date().getFullYear()

const Footer = () => (
  <div className={styles.Footer}>
    <div className="container direction-column with-gutter">
      <Row
        gutter={30}
        className={styles.socials}
        align="space-between"
        style={{ padding: '1rem 0' }}
      >
        <Col className={styles.logo} xs={24} md={12} lg={7}>
          <img
            src={LogoIcon}
            style={{ maxWidth: '153px', width: '100%' }}
            alt="logo"
          />
          <p>Group Training Scaled</p>
          <Space direction="horizontal">
            <a
              href="https://www.linkedin.com/company/gettapestree/"
              target="_blank"
              rel="noreferrer"
            >
              <DribbleIcon />
            </a>
            <a
              href="https://twitter.com/tapestree_"
              target="_blank"
              rel="noreferrer"
            >
              <TwitterIcon />
            </a>
            <a
              href="https://www.youtube.com/channel/UCrPTGhiuaQBC5nDPzYX7brw"
              target="_blank"
              rel="noreferrer"
            >
              <YoutubeIcon />
            </a>
          </Space>
        </Col>
        <Col xs={24} md={12} lg={15}>
          <Row gutter={30}>
            <Col span={24} lg={8}>
              <h2 style={{ color: ' #0B9ED0' }}>Company</h2>
              <h4>
                <Link to="/about-us">About</Link>
              </h4>
              <h4>
                <a
                  href="https://chisel-elbow-ca6.notion.site/Tapestree-s-jobs-board-fefd698364ce4c3c88f4625d4e55f5a2"
                  target="blank"
                >
                  Careers
                </a>
              </h4>
            </Col>
            <Col span={24} lg={8}>
              <h2 style={{ color: ' #0B9ED0' }}>Resource</h2>
              <h4>
                <a
                  href="https://www.youtube.com/watch?v=ZBQSPc6mYT8&feature=youtu.be"
                  target="blank"
                >
                  Demo Video
                </a>
              </h4>
              <h4>
                <Link to="/#" disabled>
                  Blog (Coming Soon)
                </Link>
              </h4>
              <h4>
                <Link to="/Whitepaper">Whitepaper</Link>
              </h4>
            </Col>
            <Col span={24} lg={8}>
              <h2 style={{ color: ' #0B9ED0' }}>Contact</h2>

              <h4>
                <a
                  target="_blank"
                  href="mailto:info@gettapestree.com"
                  rel="noreferrer"
                >
                  info@gettapestree.com
                </a>
              </h4>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  </div>
)

export default memo(Footer)

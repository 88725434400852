import React, { useState } from 'react'
import { Layout } from 'antd'
import Header from './header'
import Footer from './footer'
import styles from './layout.module.less'

const BasicLayout = (props) => {
  return (
    <Layout>
      <Header className={`${styles.Header} ${styles.HeaderWithBg}`} />
      <Layout.Content style={{ backgroundColor: '#fff' }}>
        {props.children}
      </Layout.Content>
      <Footer />
    </Layout>
  )
}

const SimpleBlogLayout = (props) => {
  return (
    <Layout>
      <Header className={`${styles.Header} ${styles.HeaderWithBg}`} />
      <Layout.Content style={{ backgroundColor: '#fff' }}>
        {props.children}
      </Layout.Content>
      <Footer />
    </Layout>
  )
}

const LandingLayout = (props) => {
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      {props.children}
      <Footer />
    </Layout>
  )
}

export { BasicLayout, SimpleBlogLayout, LandingLayout }

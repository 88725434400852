import React from 'react'
import { hot } from 'react-hot-loader/root'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

// All Routes
import Routes from './Routes'
import Alert from './components/Alert'

const history = createBrowserHistory()

class App extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className="App">
        <Alert />
        <Router history={history}>
          <Routes />
        </Router>
      </div>
    )
  }
}

export default hot(App)

import React, { memo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { MenuOutlined, DownOutlined } from '@ant-design/icons'
import { Layout, Button, Dropdown } from 'antd'

import LogoDark from '@/assets/images/logo_dark.png'
import styles from './layout.module.less'
import { Menu } from 'antd'

const Header = (props) => {
  const { signupBtnClass } = props
  const { push } = useHistory()

  const productMenuItems = (isResponsive) => (
    <Menu
      className={`${styles.productMenuAll} ${
        !isResponsive ? styles.productMenu : ''
      }`}
    >
      <Menu.Item className={!isResponsive ? styles.productLink : ''}>
        <Button type="text">
          <NavLink to="/scenario-builder">Scenario Builder</NavLink>
        </Button>
      </Menu.Item>
      <Menu.Item className={!isResponsive ? styles.productLink : ''}>
        <Button type="text">
          <NavLink to="/data-analytics">Data Analytics</NavLink>
        </Button>
      </Menu.Item>
      <Menu.Item className={!isResponsive ? styles.productLink : ''}>
        <Button type="text">
          <NavLink to="/ai-assisted-progressions">
            AI-Assisted Progressions
          </NavLink>
        </Button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Layout.Header className={styles.Header} {...props}>
      <img
        src={LogoDark}
        width="151"
        height="39"
        alt="logo"
        onClick={() => push('/')}
        style={{ cursor: 'pointer' }}
      />
      <div className={styles.Navbar}>
        <Dropdown overlay={productMenuItems()}>
          <Button type="text" className={styles.BlogLink}>
            <span className={styles.menuItemTitle}>Products</span>
            <DownOutlined />
          </Button>
        </Dropdown>

        <Button type="text" className={styles.BlogLink}>
          <NavLink to="/sales">For Sales</NavLink>
        </Button>
        <Button type="text" className={styles.BlogLink}>
          <NavLink to="/about-us">About</NavLink>
        </Button>
        {/* <Button type="text" className={styles.BlogLink}>
          <NavLink to="/training">Training</NavLink>
        </Button> */}
        <Button type="text" className={styles.BlogLink}>
          <NavLink to="/contact-us">Contact Us</NavLink>
        </Button>
      </div>
      <div className={styles.Right}>
        <Button
          type="ghost"
          className={styles.loginInBtn}
          target="_blank"
          href={`${process.env.MAIN_ADMIN_SITE_BASE_URL}/signin`}
        >
          Log in
        </Button>
        <Button
          type="ghost"
          className={`${styles.signUpBtn} ${signupBtnClass}`}
          target="_blank"
          href={`${process.env.MAIN_ADMIN_SITE_BASE_URL}/signup`}
        >
          Sign Up
        </Button>
        <div className={styles.Menubar}>
          {/* <Button
            type="default"
            htmlType="button"
            href={
              'https://tapestreee.surveysparrow.com/s/Free-Trial-Sign-Up/tt-qDD1k1wRB2NzysVwjpwLhj'
            }
            className={styles.SignupBtn}
          >
            Free Trial
          </Button> */}
          <div className={styles.dropdown}>
            <button className={styles.dropbtn}>
              <MenuOutlined />
            </button>
            <div className={styles.dropdownContent}>
              <Dropdown
                overlay={productMenuItems(true)}
                placement="bottomRight"
              >
                <Button type="text">
                  Products <DownOutlined />
                </Button>
              </Dropdown>
              <NavLink to="/sales">For Sales</NavLink>
              <NavLink to="/about-us">About</NavLink>
              {/* <NavLink to="/training">Training</NavLink> */}
              <NavLink to="/contact-us">Contact Us</NavLink>
              <a
                rel="noreferrer"
                target="_blank"
                href={`${process.env.MAIN_ADMIN_SITE_BASE_URL}/signin`}
              >
                Log In
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout.Header>
  )
}

export default memo(Header)

import * as React from 'react'
const DribbleIcon = (props) => (
  <svg
    width={28}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.833252}
      y={0.968506}
      width={26.6667}
      height={26.6667}
      rx={6.66667}
      fill="#0B9ED0"
    />
    <g clipPath="url(#clip0_14_2672)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1667 7.63525C10.4861 7.63525 7.5 10.6214 7.5 14.3019C7.5 17.9825 10.4861 20.9686 14.1667 20.9686C17.84 20.9686 20.8333 17.9825 20.8333 14.3019C20.8333 10.6214 17.84 7.63525 14.1667 7.63525ZM18.57 10.708C19.3908 11.7077 19.845 12.958 19.8572 14.2514C19.6694 14.2153 17.7894 13.8319 15.895 14.0708C15.8511 13.9764 15.815 13.8753 15.7717 13.7741C15.6538 13.496 15.5286 13.2211 15.3961 12.9497C17.4928 12.0964 18.4472 10.8675 18.57 10.708ZM14.1667 8.61914C15.6128 8.61914 16.9361 9.16081 17.9411 10.0503C17.84 10.1947 16.9794 11.3447 14.955 12.1036C14.0217 10.3903 12.9883 8.98692 12.8289 8.77025C13.2674 8.66786 13.7164 8.61696 14.1667 8.61859V8.61914ZM11.7444 9.15359C11.8961 9.35636 12.9083 10.7664 13.8556 12.4436C11.1944 13.1525 8.84444 13.138 8.59167 13.138C8.96056 11.3736 10.1539 9.90581 11.7444 9.15359ZM8.46889 14.3091V14.1358C8.715 14.143 11.4767 14.1791 14.3189 13.3258C14.485 13.6441 14.6367 13.9691 14.7811 14.2947C14.7089 14.3164 14.6294 14.338 14.5572 14.3597C11.6217 15.3069 10.0594 17.8953 9.92944 18.1125C8.98912 17.0689 8.46878 15.7139 8.46889 14.3091ZM14.1667 19.9997C12.9011 20.0017 11.6715 19.5792 10.6744 18.7997C10.7756 18.5897 11.9322 16.3625 15.1428 15.2419C15.1572 15.2347 15.165 15.2347 15.1789 15.2275C15.9817 17.3025 16.3067 19.0453 16.3939 19.5441C15.7067 19.8408 14.955 19.9997 14.1667 19.9997ZM17.3411 19.0236C17.2833 18.6764 16.9794 17.0136 16.2344 14.9669C18.0206 14.6853 19.5822 15.148 19.7778 15.213C19.5317 16.7964 18.6206 18.163 17.3411 19.0236Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_14_2672">
        <rect
          width={13.3333}
          height={13.3333}
          fill="white"
          transform="translate(7.5 7.63525)"
        />
      </clipPath>
    </defs>
  </svg>
)
export default DribbleIcon

import * as React from 'react'
const TwitterIcon = (props) => (
  <svg
    width={27}
    height={28}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={0.166748}
      y={0.968506}
      width={26.6667}
      height={26.6667}
      rx={6.66667}
      fill="#0B9ED0"
    />
    <path
      d="M19.0554 9.85738C19.0554 9.85738 18.6666 11.024 17.9443 11.7463C18.8332 17.3018 12.7221 21.3574 7.94434 18.1907C9.16656 18.2463 10.3888 17.8574 11.2777 17.0796C8.49989 16.2463 7.111 12.9685 8.49989 10.4129C9.72211 11.8574 11.611 12.6907 13.4999 12.6352C12.9999 10.3018 15.7221 8.96849 17.3888 10.524C17.9999 10.524 19.0554 9.85738 19.0554 9.85738Z"
      stroke="white"
      strokeWidth={0.833333}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default TwitterIcon
